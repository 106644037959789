import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layouts/MdxLayout.js";
import SEO from "../components/Seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Minimal-intervention Washington state wines." description="Quality fruit meets minimal-intervention methods. Minimal-intervention Washington state wines by Archibald James." mdxType="SEO" />
    <article className="mx-auto prose prose-xl">
      <h1 {...{
        "id": "product-list"
      }}>{`Product List`}</h1>
      <h2 {...{
        "id": "wine"
      }}>{`Wine`}</h2>
      <p><strong parentName="p">{`2018`}</strong></p>
      <ul>
        <li parentName="ul">{`Grenache Noir`}</li>
        <li parentName="ul">{`Cabernet Sauvignon CS2`}</li>
        <li parentName="ul">{`Viognier Bin2`}</li>
        <li parentName="ul">{`Petite Sirah`}
          <ul parentName="li">
            <li parentName="ul">{`Intensely floral and fruity, characterized by dried blueberries and dark plums, layered with black pepper, dark cacao, and fresh anise.`}</li>
            <li parentName="ul">{`Enlivened on the palate with bright but balanced acidity and textured by moderate tannins.`}</li>
            <li parentName="ul">{`Drink it now or allow it to cellar 8-10 years to elucidate tobacco, cacao, and black tea.`}</li>
            <li parentName="ul">{`Alcohol 15.7%`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Cabernet Sauvignon CS1`}
          <ul parentName="li">
            <li parentName="ul">{`Attenuated acidity with notes of anise, ripe currants, and dark cherry. Hints of cacao, peppercorns, and wood spice. Ripe tannins lend rich texture and a long finish. Barrel-aged 18 months. Chill just below room temperature and allow the wine to open in the glass. Pair with roasted eggplant and tomatoes, grilled ribs and slaw, fish stew—or dark chocolate and tart berries.`}</li>
            <li parentName="ul">{`Alcohol 14.40%`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Merlot`}
          <ul parentName="li">
            <li parentName="ul">{`Bright and acidic, characterized by tart cherries and currants with notes of anise and dried fruit leathers. Modest tannins lend structure and allow this approachable wine to age 8-10 years. Pair with caprese salad, carpaccio, or fresh pasta.`}</li>
            <li parentName="ul">{`Alcohol 14.50%`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Big Blend (Grenache, Syrah, Tempranillo)`}</li>
        <li parentName="ul">{`Sauvignon Blanc`}
          <ul parentName="li">
            <li parentName="ul">{`Intense aromas of green melon, guava, passion fruit, lychees, and gooseberries. Notes of cut grass and asparagus. Aged 16 months on the yeast lees in neutral oak, developing deep aroma, flavor, and texture. Great acidity with a touch of residual sweetness to balance. Lively with lots of character. Chill and allow it to open in the glass. Cellars 5+ years.`}</li>
            <li parentName="ul">{`Alcohol 14%`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Viognier Bin1`}</li>
        <li parentName="ul">{`Chardonnay`}</li>
        <li parentName="ul">{`Pinot Gris`}
          <ul parentName="li">
            <li parentName="ul">{`Ripe tropical fruit, lychee, and floral tones with the characteristic spice of pinot gris on the finish.
Drink just below room temperature, paired with acidic fruits, sharp cheeses, or anything fire-grilled.`}</li>
            <li parentName="ul">{`Alcohol 14.1%`}</li>
          </ul>
        </li>
      </ul>
      <p><strong parentName="p">{`2019`}</strong></p>
      <ul>
        <li parentName="ul">{`Rosé of Saignée`}</li>
      </ul>
      <p><strong parentName="p">{`2020`}</strong></p>
      <ul>
        <li parentName="ul">{`Sparkling Orange Muscat`}
          <ul parentName="li">
            <li parentName="ul">{`Bright citrus and floral notes dominate. Fermented slowly in stainless, then cold-crashed to arrest fermentation, leaving a touch of sweetness behind. Lightly carbonated to accentuate the fruit and balance acidity.`}</li>
            <li parentName="ul">{`Alcohol 11.1%`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Sparkling Rosé of Saignée`}</li>
      </ul>
      <h2 {...{
        "id": "cider"
      }}>{`Cider`}</h2>
      <p><strong parentName="p">{`Heritage Dry Ciders`}</strong>{` (deliciously dry, made from unique apple varieties)`}</p>
      <ul>
        <li parentName="ul">{`Yarlington Mill (classic English bittersweet, aged in neutral oak for 9 months, then bottle-conditioned)`}</li>
        <li parentName="ul">{`Spitzenburg`}
          <ul parentName="li">
            <li parentName="ul">{`Bottle-conditioned North American heirloom with delicate aromatic qualities, clean, light in body, moderate acidity, low astringency. Mineral and flint character, notes of violets, blond honey, quince, and pear.`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Diamondback Blend (Muscat de Bernay, Chisel Jersey, Bramley Seedling, Harry Masters Jersey, Golden Russet)`}</li>
        <li parentName="ul">{`Kingston Black (bottle-conditioned, barrel-fermented and aged dry cider)`}</li>
        <li parentName="ul">{`Wickson Crabapple (bottle-conditioned, barrel-fermented and aged dry cider)`}</li>
        <li parentName="ul">{`Cider-Apple Field Blend`}</li>
        <li parentName="ul">{`Dry Winter Blend`}
          <ul parentName="li">
            <li parentName="ul">{`Dry blend of heirloom and crabapples aged 10-12 months in oak barrels`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Rubus Wild Ride`}
          <ul parentName="li">
            <li parentName="ul">{`Barrel fermentation of blackberries with heirloom and Dolgo crabapples. Vibrant blackberry character with aromas from native yeast and bacteria, plus a sour profile from the secondary fermentation and Dolgo apples. Drink chilled.`}</li>
          </ul>
        </li>
      </ul>
      <p><strong parentName="p">{`Heritage Off-Dry Ciders`}</strong>{` (heritage apple varieties with a touch of residual sweetness)`}</p>
      <ul>
        <li parentName="ul">{`Mélange Temps I`}
          <ul parentName="li">
            <li parentName="ul">{`Field blend of heirloom and crabapples, barrel-aged over 14 months with a long secondary fermentation to soften and characterize this off-dry cider. Yarlington Mill, Porters Perfection, and Golden Russet bring bittersharp tannin and dark, earthy aromas. Cold-crashed Ashmead's Kernel, Cox Orange Pippin, and Grimes Golden bring sweetness, bright acidity, and layers of fruit.`}</li>
            <li parentName="ul">{`Alcohol 6.2%`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Heirloom Field Blend`}</li>
        <li parentName="ul">{`Cider Blend 319`}</li>
        <li parentName="ul">{`Peachy-Cot`}</li>
      </ul>
      <p><strong parentName="p">{`Cellar Series: Seasonal Small Batches`}</strong></p>
      <ul>
        <li parentName="ul">{`Ohm`}
          <ul parentName="li">
            <li parentName="ul">{`Heirloom apples fermented on the skins of Orange Muscat grapes, allowing the wild yeasts and bacteria to transform this unique cider and lend distinctive orange blossom notes and honeysuckle aromas. Bright citrus acidity balanced by light sweetness and tannins to fill the mid-palate. Drink chilled.`}</li>
          </ul>
        </li>
        <li parentName="ul">{`"Better Together" Collaboration Cider`}</li>
        <li parentName="ul">{`Pippin-Skeena (dry, Pippin apples co-fermented with Skeena cherries)`}</li>
        <li parentName="ul">{`Dolgo Blackberry Sour`}</li>
        <li parentName="ul">{`Cot in the Rain (off-dry, co-fermented with organic apricots, backsweetened with pinot gris)`}</li>
        <li parentName="ul">{`Cot in the Sun (dry, co-fermented with organic apricots)`}</li>
        <li parentName="ul">{`Cerise Sèche (dry, co-fermented with a blend of organic local cherries)`}</li>
        <li parentName="ul">{`Bluet Pass (organic Minneiska apples co-fermented with organic blueberries)`}</li>
      </ul>
      <p><strong parentName="p">{`Organic Dessert Apple Ciders`}</strong>{` (made from the kind of apples you'd find in the grocery store)`}</p>
      <ul>
        <li parentName="ul">{`Thai'd Up (off-dry cider co-fermented with lemongrass, ginger, basil, turmeric; 2018-2020)`}</li>
        <li parentName="ul">{`SmashApple (off-dry cider, like biting into a fresh apple; 2018-2020)`}</li>
        <li parentName="ul">{`Dry-Sider (deliciously dry cider; 2018-2020)`}</li>
        <li parentName="ul">{`Solstice Cyzer (dry cider co-fermented with buckwheat honey and lavender; 2018)`}</li>
      </ul>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      